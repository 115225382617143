<template>
  <div>
    <b-row>
        <b-col sm="12">
            <b-card header-tag="header" footer-tag="footer">
                <b-row>
                    <b-col>
                        <h4 id="traffic" class="card-title mb-0">Configuración - Whatsapp Marketing</h4>
                        <div class="small text-muted">Configuraciones generales</div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card header-tag="header" footer-tag="footer" no-body>
                <b-row>
                    <b-col>      
                        <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
                            <b-tab title="General">
                              <b-form-group label="Mensajes por Minutos">
                                <b-row>
                                  <b-col md="3">                                    
                                    <b-form-input 
                                      v-model="crud.form.ID1.value_number"
                                      placeholder="Cantidad de Mensajes" 
                                      size="sm"
                                      type="number">
                                    </b-form-input>
                                  </b-col>
                                  <b-col md="1">
                                    <b-button 
                                      variant="dark" 
                                      size="sm" 
                                      class="btn-block"
                                      @click="saveOne()">
                                      Guardar
                                    </b-button>
                                  </b-col>                                  
                                </b-row>
                              </b-form-group>                              
                            </b-tab>                 
                            <b-tab title="WebHook">
                              <b-form-group label="WebHook Configurado">
                                <b-row>
                                  <b-col md="3"> 
                                    <b-form-select 
                                      v-model="crud.webhook.notification_whatsapp_id" 
                                      :options="arr.select.notificationWhatsapp">
                                    </b-form-select>
                                  </b-col>
                                  <b-col md="7">                                    
                                    <b-form-input 
                                      :readonly="true" 
                                      size="sm"
                                      type="text"
                                      v-model="crud.webhook.webhook_url">
                                    </b-form-input>                                                    
                                  </b-col>
                                  <b-col md="2">                                     
                                    <b-button 
                                      variant="dark" 
                                      size="sm" 
                                      class="btn-block"
                                      @click="getWebbhook()">
                                      Obtener WebHook
                                    </b-button>
                                  </b-col>                                     
                                </b-row>        
                              </b-form-group>                     
                            </b-tab>
                            <b-tab title="Mensajes en Cola">
                              <b-form-group label="Mensajes en Cola">
                                <b-row>
                                  <b-col md="3"> 
                                    <b-form-select 
                                      v-model="crud.queue.notification_whatsapp_id" 
                                      :options="arr.select.notificationWhatsapp">
                                    </b-form-select>
                                  </b-col>                                  
                                  <b-col md="3">     
                                    <b-form-input 
                                      placeholder="Cantidad de Mensajes" 
                                      size="sm"
                                      type="number"
                                      :readonly="true"
                                      v-model="crud.queue.count">
                                    </b-form-input>                                    
                                  </b-col>
                                  <b-col md="2">                                     
                                    <b-button 
                                      variant="dark" 
                                      size="sm" 
                                      class="btn-block"
                                      @click="getQueue()">
                                      Obtener Mensajes en Cola
                                    </b-button>
                                  </b-col>                    
                                  <b-col md="2">                                     
                                    <b-button 
                                      variant="danger" 
                                      size="sm" 
                                      class="btn-block"
                                      @click="clearQueue()">
                                      Borrar Cola de Mensajes
                                    </b-button>
                                  </b-col>                                                                       
                                </b-row>        
                              </b-form-group>                                   
                            </b-tab>                                                    
                        </b-tabs>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>

    <b-card header-tag="header" footer-tag="footer">
      <b-row>
        <b-col>
          <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
            <i class="fa fa-angle-double-left"></i>
            Volver
          </b-button>                        
        </b-col>                  
      </b-row>
    </b-card>   
  </div> 
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  
  export default {    
    data: () => {
      return {      
        access: {
          module_id: Modules.WHATSAPP_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudSettings',
          elements: {}
        },
        crud: { 
          form: {
            ID1: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
          },
          webhook: {
            notification_whatsapp_id: 0,
            webhook_url: '',
          },
          queue: {
            notification_whatsapp_id: 0,
            count: 0,
          },          
        },
        arr: {
          select: {
            notificationWhatsapp: [],
          }
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.show()     
      this.loadNotificationWhatsapp() 
    },
    methods: {  
      show() {   
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data
          
          data.forEach(element => {            
            if(element.id == 1)  {
              this.crud.form.ID1.id = element.id
              this.crud.form.ID1.value_number = parseInt(element.value_number)
            }                    
          });          
        })
        .catch(error => {                  
          this.$awn.alert(Error.showError(error));
        })        
      },

      loadNotificationWhatsapp() {        
        var result = serviceAPI.obtenerNotificationWhatsapp()        
        result.then((response) => {
          var data = response.data    
          
          this.arr.select.notificationWhatsapp = []    

          data.forEach(element => {            
            this.arr.select.notificationWhatsapp.push({ value: element.id, text: element.name })
          });          
        })              
      }, 
      
      getWebbhook() {
        if(!this.crud.webhook.notification_whatsapp_id) {
          this.$awn.alert('Debe seleccionar una instancia de Whatsapp')
          return false;
        }

        let loader = this.$loading.show();
        var result = serviceAPI.obtenerSettingsNotificationsWhastapp(this.crud.webhook)
        result.then((response) => {
          loader.hide()
          var data = response.data
          
          this.crud.webhook.webhook_url = data.webhookUrl          
        })
        .catch(error => {                  
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },

      getQueue() {
        if(!this.crud.queue.notification_whatsapp_id) {
          this.$awn.alert('Debe seleccionar una instancia de Whatsapp')
          return false;
        }

        let loader = this.$loading.show();
        var result = serviceAPI.obtenerQueueNotificationsWhastapp(this.crud.queue)
        result.then((response) => {
          loader.hide()
          var data = response.data
          
          this.crud.queue.count = data.totalMessages
        })
        .catch(error => {        
          loader.hide()          
          this.$awn.alert(Error.showError(error));
        })        
      },      
      clearQueue() {
        if(!this.crud.queue.notification_whatsapp_id) {
          this.$awn.alert('Debe seleccionar una instancia de Whatsapp')
          return false;
        }

        this.$bvModal.msgBoxConfirm('¿Desea limpiar la cola de mensajes?', {
          title: 'Cola de mensajes',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'LIMPIAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {

            let loader = this.$loading.show();
            var result = serviceAPI.limpiarQueueNotificationsWhastapp(this.crud.queue)
            result.then((response) => {                            
              loader.hide() 

              this.crud.queue.count = 0
              this.$awn.success("Se vacio la cola de mensajes");
            })
            .catch(error => {         
              loader.hide()          
              this.$awn.alert(Error.showError(error));
            })        
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })        
      },   

      // save configurations       
      saveOne() {   
        if(this.crud.form.ID1.value_number > 30) {
          this.$awn.alert('La cantidad de mensajes por minutos no puede ser mayor a 30 mensajes');
          this.show()
          return false;
        }
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID1);  
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },       
    } 
  }
</script>